import parseAPNG from 'apng-js';
import isNotAPNG from 'apng-js';
import axios from 'axios';
import BigNumber from 'bignumber.js';
// eslint-disable-next-line import/order
import qs from 'qs';
import removeTrailingZeros from 'remove-trailing-zeros';
import formatNumber from 'simple-format-number';
// @ts-ignore
// eslint-disable-next-line import/order
import * as uuid from 'uuid';
import { toast } from 'vue3-toastify';
// @ts-ignore
// @ts-ignore
import 'vue3-toastify/dist/index.css';

import { useMobile } from '~/composables/useMobile';

import ChannelService from '~/libs/ChannelService';
import Tracker, { type RoutePathEventType } from '~/libs/Tracker';
import { getTimeDifference, now, safeDateParse } from '~/libs/dateUtils';

import { useBasicsStore } from '~/services/base';

import ImageTypes, { type ImageType } from './ImageTypes';
import { type BoardContent, type GetterBoardContent } from './community';
import { type FlattenMenuItem, type MenuItem, type UserGnbCategory } from './gnb/type';
import { type CachedPopupData, type DesignPopup, type DisplayProduct, LinkType, type Product } from './main/type';
import { type ProductCategory, type ProductCategoryTreeNode } from './product/category/type';
import { type UserInfo, useUserAuthStore } from './userAuth';

export default class Utility {
  static async downloadFile(
    file: { fileOrg: string; fileUrl: string },
    options: { withCredentials: boolean } = { withCredentials: true },
  ) {
    try {
      // 호출 위치가 인앱 브라우저인지 체크 (인앱브라우저에서는 아래의 blob 방식이 이미지에는 정상작동하지 않음)
      if (
        navigator.userAgent.match(
          /inapp|NAVER|KAKAOTALK|Snapchat|Line|WirtschaftsWoche|Thunderbird|Instagram|everytimeApp|WhatsApp|Electron|wadiz|AliApp|zumapp|iPhone(.*)Whale|Android(.*)Whale|kakaostory|band|twitter|DaumApps|DaumDevice\/mobile|FB_IAB|FB4A|FBAN|FBIOS|FBSS|SamsungBrowser\/[^1]/i,
        )
      ) {
        window.location.href = file.fileUrl;
        return;
      }

      const { withCredentials } = options;

      const response = await axios.get(file.fileUrl, {
        responseType: 'blob', // Force to receive data in a Blob Format
        withCredentials,
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.fileOrg);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error:', error);
      window.location.href = file.fileUrl;
    }
  }

  static shareKakao(param: { thumbnailUrl: string; path: string }) {
    // @ts-ignore
    window.Kakao.Share.sendCustom({
      templateId: 95328,
      // 카카오톡이 설치 되지 않았을때 마켓으로 이동
      installTalk: true,
      templateArgs: {
        THU: param!.thumbnailUrl,
        PATH: param.path,
      },
    });
  }

  static parseBoolean(value: any) {
    if (typeof value === 'boolean') {
      return value;
    }

    if (typeof value === 'string') {
      switch (value.toLowerCase().trim()) {
        case 'true':
        case 'yes':
        case '1':
          return true;
        case 'false':
        case 'no':
        case '0':
        case null:
          return false;
        default:
          return Boolean(value);
      }
    }

    if (typeof value === 'number') {
      return Boolean(value);
    }

    // For other types (like objects, arrays, null, undefined), just return a boolean conversion
    return Boolean(value);
  }

  static toFixed(price: string | number, decimalCount = 0): string {
    // Ensure the price is a BigNumber before calling toFixed()
    const priceBN = new BigNumber(price);
    return priceBN.toFixed(decimalCount);
  }

  static isEndProduct(product: Product): boolean {
    return product.endYn == 'Y' || product.endDateYn == 'Y';
  }

  static refineReviewCount(reviewCount?: number, isWithPlusStr = true): string {
    if (reviewCount == null) {
      return '0';
    }

    if (reviewCount <= 999999) {
      return Intl && Intl.NumberFormat ? new Intl.NumberFormat().format(reviewCount) : reviewCount.toString();
    }

    return '+999,999';
  }

  static hasCachedInOneDay(seq: number, type: string): boolean {
    return this.hasCachedForCustomHours(seq, type, 24);
  }

  static hasCachedForever(seq: number, type: string): boolean {
    return this.hasCachedForCustomHours(seq, type, 24 * 365 * 10); // 10 년
  }

  static hasCachedForCustomHours(seq: number, type: string, hours = 24): boolean {
    if (seq == null) {
      return false;
    }
    const userAuthStore = useUserAuthStore();
    const cachedItem = userAuthStore.cachedPopupData[type + seq];
    if (cachedItem) {
      const lastOpenedDate = safeDateParse(cachedItem.lastOpenedDate as Date); // assuming lastOpenedDate is in a format dayjs can understand

      console.log({ lastOpenedDate });

      const twentyFourHoursAgo = now().subtract(hours, 'hours');

      if (lastOpenedDate.isAfter(twentyFourHoursAgo)) {
        console.log('The lastOpenedDate is within the last 24 hours');
        return true;
      } else {
        console.log('The lastOpenedDate is not within the last 24 hours');
        return false;
      }
    }
    return false;
  }

  static removeCacheLastOpenSeq(item: CachedPopupData) {
    const userAuthStore = useUserAuthStore();
    delete userAuthStore.cachedPopupData[item.type + item.seq];
  }

  static cacheLastOpenSeq(item: CachedPopupData) {
    const userAuthStore = useUserAuthStore();
    userAuthStore.cachedPopupData[item.type + item.seq] = {
      seq: item.seq,
      type: item.type,
      lastOpenedDate: now().toDate(), // now
    };
  }

  static hasMonthlyPlan(monthlyPlan: number | null | undefined): boolean {
    if (monthlyPlan == null || monthlyPlan == 1 || monthlyPlan == 0) {
      return false;
    }
    return true;
  }

  // 팝업 가져 오기(M=메인,C=카테고리,B=커뮤니티게시판)
  static getDesignPopupList(
    designPopupList: DesignPopup[],
    designPopupViewArea: string | 'M' | 'C' | 'B',
  ): DesignPopup[] {
    return designPopupList.filter((item: DesignPopup) => item.designPopupViewArea == designPopupViewArea);
  }

  static getHex(value: string | null): string {
    if (value == null) {
      return '#000000';
    }
    if (value.startsWith('#')) {
      return value;
    }
    return '#' + value;
  }

  static openLink(linkUrl: string, linkType: LinkType) {
    console.log('openLink', linkType, linkUrl);
    // check start with https
    if (linkUrl.startsWith('http') == false) {
      linkUrl = `https://${linkUrl}`;
    }
    if (linkType == 'P') {
      // 페이지 이동
      window.location.href = linkUrl;
    } else if (linkType == 'N') {
      // 새창 이동
      window.open(linkUrl);
    }
  }

  static checkLoginThenRedirect(message?: string, isWithLoginAlert = true): Promise<boolean> {
    const authStore = useUserAuthStore();
    const router = useRouter();
    const route = useRoute();
    const isLogined = !$isEmpty(authStore.user);
    const basicStore = useBasicsStore();

    if (isLogined == true) {
      // do nothing
      return true;
    } else {
      basicStore.setLoginModalState(true);
      return false;
    }
  }

  static isOkRes(res: any): boolean {
    if ($isEmpty(res.data.status.errorCode)) {
      return true;
    }
    return false;
  }

  static getUserName(user: UserInfo | null): string | undefined {
    if (user == null) {
      return undefined;
    }
    return $isEmpty(user.nickName) ? user.userName : user.nickName;
  }

  static backPage(fallbackUrl: string) {
    const referrer = document.referrer;
    const currentHost = window.location.host;

    // Attempt to extract the domain from the referrer URL
    let referrerHost = '';
    if (referrer) {
      const url = new URL(referrer);
      referrerHost = url.host;
    }

    // If the domains match, navigate back
    if (referrerHost === currentHost) {
      window.history.back();
    } else {
      // Otherwise, redirect to the fallback URL
      window.location.href = fallbackUrl;
    }
  }

  static flattenTreeNode(foundNode: MenuItem) {
    return foundNode.items?.reduce((flattenedItems: FlattenMenuItem[], item: MenuItem) => {
      const flattenedItem: FlattenMenuItem = {
        label: item.label,
        key: item.key,
        class: item.class,
        data: item.data,
        items: [],
      };
      if (item.items) {
        flattenedItem.items = item.items.reduce((nestedItems: FlattenMenuItem[], nestedItem: MenuItem) => {
          const nestedFlattenedItem: FlattenMenuItem = {
            label: nestedItem.label,
            key: nestedItem.key,
            class: nestedItem.class,
            data: nestedItem.data,
            items: [],
          };
          nestedItems.push(nestedFlattenedItem);
          return nestedItems;
        }, []);
      }
      flattenedItems.push(flattenedItem);
      return flattenedItems;
    }, []);
  }

  static findItemByCateSeq(tree: MenuItem[], cateSeq: string | null): MenuItem | null {
    for (const item of tree) {
      if (item.key == cateSeq) {
        return item;
      }

      if (item.items) {
        const foundItem = this.findItemByCateSeq(item.items, cateSeq);
        if (foundItem) {
          return foundItem;
        }
      }
    }

    return null;
  }

  static findActiveNode(tree: MenuItem[]): MenuItem | null {
    for (const item of tree) {
      if (item.class === 'active') {
        return item;
      }

      if (item.items) {
        const foundActiveNode = this.findActiveNode(item.items);
        if (foundActiveNode) {
          return foundActiveNode;
        }
      }
    }

    return null;
  }

  static categoryToTree(categories: UserGnbCategory[]): MenuItem[] {
    const tree: MenuItem[] = [];

    const categoryMap: Map<number, MenuItem & { parentSeq: number | null }> = new Map(
      categories.map((category) => [
        category.cateSeq,
        {
          label: category.cateName,
          to: '/class?cateSeq=' + category.cateSeq.toString(),
          key: category.cateSeq.toString(),
          items: [],
          parentSeq: category.catePSeq,
          data: category,
        },
      ]),
    );

    categoryMap.forEach((category) => {
      if (category.parentSeq === null) {
        tree.push(category);
      } else {
        const parent = categoryMap.get(category.parentSeq);
        if (parent) {
          if (!parent.items) {
            parent.items = [];
          }
          parent.items.push(category);
        }
      }
    });

    return tree;
  }

  static surveyKeys = ['area', 'job', 'pos', 'head', 'admin', 'sex'];

  static showToastInfo(text: string) {
    toast(text, {
      // autoClose: 30,
      hideProgressBar: true,
    });
  }

  static hasSameDisplaySeq(displayList: any[], displaySeq: number | undefined): boolean {
    const hasSame = displayList.filter((item) => item.displaySeq == displaySeq);
    if (hasSame.length > 0) {
      return true;
    }
    return false;
  }

  static getUUID(): string {
    return uuid.v1();
  }

  public static convertToNodes(items: ProductCategory[], parentKey?: number | null): ProductCategoryTreeNode[] {
    const nodes: ProductCategoryTreeNode[] = [];

    const createNode = (item: ProductCategory, children: ProductCategoryTreeNode[]) => {
      const node: ProductCategoryTreeNode = {
        key: item.cateSeq,
        label: item.cateName,
        count: item.contentsCnt == 0 ? null : item.contentsCnt,
        children,
        categoryData: item,
      };
      return node;
    };

    for (const item of items) {
      if (item.catePSeq == parentKey) {
        const children = this.convertToNodes(items, item.cateSeq);
        nodes.push(createNode(item, children == null ? [] : children));
      }
    }

    return nodes;
  }

  public static firstCharUppercase(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  public static setCommaPer3Digit(num: number | string | null) {
    if ($isEmpty(num)) {
      return 0;
    }
    if (typeof num === 'string') {
      num = num.replaceAll(',', '');
      num = parseInt(num);
    }
    try {
      const value = formatNumber(num, { fractionDigits: 4 });

      if (value == 0) {
        return '0';
      }

      return removeTrailingZeros(value);
    } catch (e) {
      console.error(e);
      return 0;
    }
  }

  public static sameAsAssignObject(targetObj: any = {}, sourceObj: any = {}) {
    for (const target in targetObj) {
      for (const source in sourceObj) {
        if (target === source) {
          targetObj[target] = sourceObj[source];
        }
      }
    }
  }

  public static extractOnlyString(str: string) {
    return new DOMParser().parseFromString(str, 'text/html').documentElement.textContent;
  }

  public static handleCommunityContent(item: BoardContent): GetterBoardContent {
    return {
      ...item,
      nameLabel: item.nickName || item.userName,
      boardContentsPreviewText: Utility.extractOnlyString(item.boardContentsText),
      historyTimeLabel: getTimeDifference(item.regDate),
      viewCountLabel: Utility.setCommaPer3Digit(item.viewCount),
      commentCountLabel: Utility.setCommaPer3Digit(item.commentCount),
      hateCountLabel: Utility.setCommaPer3Digit(item.hateCount),
      likeCountLabel: Utility.setCommaPer3Digit(item.likeCount),
    };
  }

  public static convertSecondToTime = (num: number) => {
    // if(num < 60 ) return `${num % 60}초`
    // if(num < 3600) return `${Math.floor(num/60)}분 ${num % 60}초`
    const h = Math.floor(num / 3600);
    const m = Math.floor((num % 3600) / 60);
    const s = num % 60;
    return `${h}:${m}:${s}`;
  };

  public static adjustIframeDimensions(iframeHTML: string): string {
    // HTML 문자열을 DOM 요소로 변환
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(iframeHTML, 'text/html');

    // 모든 iframe 태그를 찾아 콘테이너 내부로 이동
    const iframeElements = htmlDoc.querySelectorAll('iframe');
    iframeElements.forEach((iframe) => {
      const container = htmlDoc.createElement('div');
      container.className = 'aspect-ratio';
      iframe.before(container);
      container.appendChild(iframe);
    });
    // 수정된 HTML 문자열로 다시 변환

    return htmlDoc.body.innerHTML;
  }

  public static truncateText(text: string, limit = 50): string {
    if (!text || typeof text !== 'string') {
      return '';
    }

    if (text.length <= limit) {
      return text;
    }

    return `${text.substring(0, limit)}...`;
  }

  public static getPayType(payMethod: string, pg: string) {
    const PAY_METHOD = [
      { value: 'card', label: '신용카드' },
      { value: 'trans', label: '실시간계좌이체' },
      { value: 'vbank', label: '가상계좌' },
      { value: 'nbank', label: '무통장' },
      { value: 'phone', label: '휴대폰소액결제' },
      { value: 'samsung', label: '삼성페이' },
      { value: 'kpay', label: 'KPay' },
      { value: 'kakaopay', label: '카카오페이' },
      { value: 'payco', label: '페이코' },
      { value: 'lpay', label: 'LPAY' },
      { value: 'ssgpay', label: 'SSG페이' },
      { value: 'tosspay', label: '토스페이' },
      { value: 'cultureland', label: '문화상품권' },
      { value: 'smartculture', label: '스마트문상' },
      { value: 'happymoney', label: '해피머니' },
      { value: 'booknlife', label: '도서문화상품권' },
      { value: 'point', label: '포인트' },
      { value: 'wechat', label: '위쳇페이' },
      { value: 'alipay', label: '알리페이' },
      { value: 'unionpay', label: '유니온페이' },
      { value: 'tenpay', label: '텐페이' },
      { value: 'free', label: '무료신청' },
      { value: 'naverpay', label: '네이버페이' },
    ];

    let payValue = '';

    if (pg === 'naverpay' || pg === 'tosspay') {
      payValue = pg;
    } else {
      payValue = payMethod;
    }

    const findIndex = PAY_METHOD.findIndex((v) => v.value === payValue);
    return $isEmpty(payMethod) ? '' : PAY_METHOD[findIndex].label;
  }

  static goCurriculum() {
    const router = useRouter();
    router.push('/curriculum');
  }

  static hasBackHistory(): boolean {
    if (window.history.length <= 1) {
      // 처음패이지 라는뜻.
      return false;
    }
    return true;
  }

  static movePageBack(fallbackPath: string) {
    try {
      const basicsStore = useBasicsStore();
      const router = useRouter();
      if (basicsStore?.route?.from.path.startsWith('/login') || this.hasBackHistory() == false) {
        router.replace(fallbackPath);
      } else {
        // router.go(-1)
        router.back();
      }
    } catch (e) {
      console.error(e);
    }
  }

  static async openKollusUrl(contentsUrl: string) {
    // dynamic import ( ssr 때문에 )
    const AppLoader = await import('tui-app-loader');
    const UAParser = await import('ua-parser-js');
    const loader = new AppLoader.default();
    const osName = new UAParser.default().getResult().os.name;

    const schemeParam = 'path' + '?url=' + encodeURIComponent(contentsUrl);

    loader.exec({
      ios: {
        scheme: 'kollus://' + schemeParam,
        url: 'https://itunes.apple.com/app/id760006888', // AppStore URL
      },
      android: {
        intentURI: 'kollus://' + schemeParam,
      },
      timerSet: {
        ios: 4000,
        android: 4000,
      },
      etcCallback: function () {
        if (osName !== 'iOS' && osName !== 'Android') {
          // alert('모바일 단말에서 실행하셔야 합니다.');
          console.log('모바일이 아닌 단말기 임');

          window.open(contentsUrl, '_blank');
        }
      },
      notFoundCallback: function () {
        if (osName === 'iOS') {
          window.location.href = 'https://itunes.apple.com/app/id760006888'; // AppStore URL
        } else if (osName === 'Android') {
          window.location.href = 'market://details?id=com.kollus.media'; // Android package name
        }
      },
    });
  }

  static checkIfPngIsAnimated(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = async function (event: any) {
        const buffer = new Uint8Array(event.target.result);
        try {
          const anim: any = await parseAPNG(buffer);

          if (anim.frames.length > 0) {
            return resolve(true);
          }

          // @ts-ignore
          if (isNotAPNG(anim)) {
            throw new Error(anim);
          }
          resolve(true);
        } catch (error) {
          // console.error(error);
          // error 무시.
          resolve(false);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  }

  static onChangeRoutingForChannelTalk(isSideMenuActive = false) {
    const { isMobile } = useMobile();
    const basicStore = useBasicsStore();
    const CHANNEL_TALK_STANDARD_OPEN = ['index', 'product', 'class'];
    const CHANNEL_TALK_STANDARD_MOBILE_OPEN = ['index', 'class'];

    function hideAll() {
      ChannelService.hideMessenger();
      ChannelService.hideChannelButton();
    }
    if (isSideMenuActive) {
      if (isMobile.value) {
        hideAll();
        return;
      }
    }

    hideAll();
    if (isMobile.value) {
      if (CHANNEL_TALK_STANDARD_MOBILE_OPEN.includes(basicStore.route?.to.name as string)) {
        ChannelService.showChannelButton();
      }
    } else if (CHANNEL_TALK_STANDARD_OPEN.includes(basicStore.route?.to.name as string)) {
      ChannelService.showChannelButton();
    }
  }

  static convertOldReview(text: string) {
    return text
      .replace(/<[^>]*>?/g, '')
      .replace(/&nbsp;/g, '')
      .replace(/nbsp;/g, '')
      .replace(/&amp;/g, '')
      .replace(/&lt;/g, '')
      .replace(/&gt;/g, '');
  }

  static setEventViewProduct = (product: Product): void => {
    const userAuthStore = useUserAuthStore();

    const prevPath = this.getPathExceptQuery();
    const {
      public: { frontUrl },
    } = useRuntimeConfig();

    let cateSeq = '';
    if (product.classCategoryList) {
      cateSeq = product.classCategoryList![0].cateSeq;
    }

    const route = useRoute();

    Tracker['View Product']({
      userEmail: userAuthStore.user?.email ?? '',
      displayId: String(product.displaySeq ?? ''),
      displayName: product.displayName ?? '',
      productId: String(product.productSeq ?? ''),
      productName: product.productName ?? '',
      displayCategoryId: String(product.displayCategoryId ?? ''),
      earlyBirdYn: product.earlyBirdYn ?? '',
      previewYn: product.previewYn ?? '',
      originalYn: product.originalYn ?? '',
      stayYn: product.stayYn ?? '',
      alarmYn: product.alarmYn ?? '',
      value: product.price ?? 0,
      cateSeq: cateSeq ?? '',
      referrer_url: `${frontUrl}${prevPath}`,
      referrer_path: prevPath ?? '',
      inviteCode: !!route.query.inviteCode ? 'Y' : 'N',
    });
  };

  static setRecentViewedProducts = (currentProduct: Product | DisplayProduct): void => {
    const parsedPrevProducts: (Product | DisplayProduct)[] = this.getRecentViewedProducts() ?? [];

    const hasPrevProductSeq = parsedPrevProducts
      ? parsedPrevProducts?.find((product) => {
          return product.productSeq === currentProduct.productSeq;
        })
      : [];

    if (!hasPrevProductSeq) {
      const newRecentViewedProducts: (Product | DisplayProduct)[] = [...parsedPrevProducts, currentProduct];
      const removeIdx = 30;

      if (newRecentViewedProducts.length > removeIdx) {
        const slicedProducts = newRecentViewedProducts.slice(1);
        typeof window !== 'undefined' && localStorage.setItem('recentViewedProducts', JSON.stringify(slicedProducts));
      } else {
        typeof window !== 'undefined' &&
          localStorage.setItem('recentViewedProducts', JSON.stringify(newRecentViewedProducts));
      }
    }
  };

  static getRecentViewedProducts = (): (Product | DisplayProduct)[] => {
    // TODO: Remove over 30 days..
    const prevRecentViewedProducts: string =
      typeof window !== 'undefined' ? localStorage.getItem('recentViewedProducts') : '[]';
    const parsedPrevProducts: (Product | DisplayProduct)[] = JSON.parse(prevRecentViewedProducts);
    return parsedPrevProducts;
  };

  static onMovePage = (path: string, top?: any) => {
    const router = useRouter();

    let suffix = '';
    if (path.includes('?')) {
      suffix = '&ref=' + (Math.random() * 1000000).toFixed(0);
    } else {
      suffix = '?ref=' + (Math.random() * 1000000).toFixed(0);
    }
    router.push(path + suffix); // 이거 붙여줘야 같은 path 도 이동함.

    top ? window.scrollTo(0, 0) : '';
  };

  static onMovePageWithEvent = (path: string, eventParams?: { categoryId: string; className: string }) => {
    this.onMovePage(path);
    Tracker['Click Hamburger Category']({
      tabName: '클래스',
      categoryId: eventParams?.categoryId,
      text: eventParams?.className,
    });
  };

  static getPathExceptQuery = (): string => {
    const prevPath = typeof window !== 'undefined' ? sessionStorage.getItem('prevPath') : null;
    const splittedWithQuestion: string[] = prevPath?.split('?');
    if (splittedWithQuestion.length > 1) {
      return splittedWithQuestion[0];
    }
    return prevPath;
  };

  static navToPrevPath = (defaultPath: string): string => {
    const route = useRoute();
    const isWindowDefined = typeof window !== 'undefined';
    if (isWindowDefined) {
      const prevPath = sessionStorage?.getItem('prevPath') ?? '/';
      const isRefreshed = route.path === prevPath;
      if (prevPath && !isRefreshed) {
        return prevPath;
      }
      return defaultPath;
    }
    return defaultPath;
  };

  static checkIsValidImgUrl = (imgUrl: string | null): boolean => {
    const hasImg = !!imgUrl && imgUrl !== '';
    return hasImg;
  };

  static isLogined(user: UserInfo | null): boolean {
    return !$isEmpty(user);
  }

  static getOptimized(originalUrl: string | null | undefined, imageType: ImageType | object) {
    if (!originalUrl?.includes('weolbu.com') || !originalUrl || originalUrl === '') {
      return originalUrl;
    }
    if (typeof imageType === 'string' && !ImageTypes[imageType]) {
      return originalUrl;
    }

    // 이미지 타입이 object 일 경우
    if (typeof imageType === 'object') {
      return `${originalUrl}?${qs.stringify(imageType)}`;
    }
    return `${originalUrl}?${qs.stringify(ImageTypes[imageType])}`;
  }

  static getCurrentPage(pagePath: string): string | null {
    switch (pagePath) {
      case '/':
        return 'mainpage';
      case '/product':
        return 'productpage';
      case '/mypage':
        return 'mypage';
      default:
        return null;
    }
  }

  static getEventPageName(pageName: string | null): RoutePathEventType | null {
    switch (pageName) {
      case 'mypage-index':
        return { pageName: 'MYPAGE', bannerType: 'MYPAGE_SUB' };
      case 'mypage-index-studyroom-lecture':
        return { pageName: 'CURRICULUM', bannerType: 'CURRICULUM_SUB' };
      default:
        return null;
    }
  }

  static refineColor(hex: string | null) {
    if (!hex) {
      return '';
    }

    hex = Utility.getHex(hex);

    if (hex.length === 7) {
      return hex;
    } else if (hex.length === 4) {
      return `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`;
    } else {
      return '#ffffff';
    }
  }
}
