import base64Enc from 'crypto-js/enc-base64';
import md5 from 'crypto-js/md5';
import sha256 from 'crypto-js/sha256';

type DeviceType = 't' | 'm' | 'd';

declare global {
  interface Window {
    criteo_q: any[];
  }
}

class CriteoTag {
  private parterId: number | null = null;

  init() {
    const {
      public: { criteoTagPartnerId },
    } = useRuntimeConfig();

    if (criteoTagPartnerId) {
      this.parterId = Number(criteoTagPartnerId);
    }
  }

  // 디바이스 타입 가져오기
  private getDeviceType(): DeviceType {
    if (/iPad/.test(window.navigator.userAgent)) {
      return 't';
    } else if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(window.navigator.userAgent)) {
      return 'm';
    } else {
      return 'd';
    }
  }

  // 이메일 해싱
  private getHashedEmails(email: string): { sha256: string; md5: string } {
    return {
      sha256: sha256(email).toString(base64Enc),
      md5: md5(email).toString(base64Enc),
    };
  }

  // 홈페이지 방문 태그 추가
  public addViewHomeTag({ email = '' }: { email: string }) {
    if (!this.parterId) {
      return;
    }

    // window.criteo_q = window.criteo_q || [];
    window.criteo_q = [];

    const deviceType = this.getDeviceType();
    const hashedEmail = this.getHashedEmails(email);

    window.criteo_q.push(
      { event: 'setAccount', account: this.parterId },
      { event: 'setSiteType', type: deviceType },
      { event: 'setEmail', email: hashedEmail.sha256, hash_method: 'sha256' },
      { event: 'setEmail', email: hashedEmail.md5, hash_method: 'md5' },
      { event: 'viewHome' },
    );
  }

  // 상품 카테고리/검색/리스팅 태그 추가
  public addViewListTag({
    email = '',
    categoryId = '',
    searchKeyword = '',
    items = [],
  }: {
    email: string;
    categoryId: string;
    searchKeyword: string;
    items: string[];
  }) {
    if (!this.parterId) {
      return;
    }

    // window.criteo_q = window.criteo_q || [];
    window.criteo_q = [];

    const deviceType = this.getDeviceType();
    const hashedEmail = this.getHashedEmails(email);

    window.criteo_q.push(
      { event: 'setAccount', account: this.parterId },
      { event: 'setSiteType', type: deviceType },
      { event: 'setEmail', email: hashedEmail.sha256, hash_method: 'sha256' },
      { event: 'setEmail', email: hashedEmail.md5, hash_method: 'md5' },
      { event: 'viewList', item: items, category: categoryId, keywords: searchKeyword },
    );
  }

  // 상품 상세 태그 추가
  public addViewItemTag({ email = '', productId = '' }: { email: string; productId: string }) {
    if (!this.parterId) {
      return;
    }

    // window.criteo_q = window.criteo_q || [];
    window.criteo_q = [];

    const deviceType = this.getDeviceType();
    const hashedEmail = this.getHashedEmails(email);

    window.criteo_q.push(
      { event: 'setAccount', account: this.parterId },
      { event: 'setSiteType', type: deviceType },
      { event: 'setEmail', email: hashedEmail.sha256, hash_method: 'sha256' },
      { event: 'setEmail', email: hashedEmail.md5, hash_method: 'md5' },
      { event: 'viewItem', item: productId },
    );
  }

  // 세일즈 태그 추가
  public addTrackTransaction({
    email = '',
    transactionId = '',
    items = [],
  }: {
    email: string;
    transactionId?: string;
    items: any[];
  }) {
    if (!this.parterId) {
      return;
    }

    // window.criteo_q = window.criteo_q || [];
    window.criteo_q = [];

    const deviceType = this.getDeviceType();
    const hashedEmail = this.getHashedEmails(email);

    window.criteo_q.push(
      { event: 'setAccount', account: this.parterId },
      { event: 'setSiteType', type: deviceType },
      { event: 'setEmail', email: hashedEmail.sha256, hash_method: 'sha256' },
      { event: 'setEmail', email: hashedEmail.md5, hash_method: 'md5' },
      { event: 'trackTransaction', id: transactionId, item: items },
    );
  }
}

export default new CriteoTag();
