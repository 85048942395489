import dayjs from 'dayjs';
import { useUserCommonStore } from '~~/src/services/userCommon';

export const useCountdown = (endTime: Date, format: string = 'DD일 HH시간 mm분 ss초') => {
  const store = useUserCommonStore();

  const isLoading = ref(true);
  const isFinish = ref(false);

  const remainingTime = ref(dayjs(endTime).diff(dayjs()));
  const remaining = computed(() => dayjs.duration(remainingTime.value).format(format));
  const remainingToken = computed(() => {
    const timeDuration = dayjs.duration(remainingTime.value);
    const days = timeDuration.days();
    const hours = timeDuration.hours();
    const minutes = timeDuration.minutes();
    const seconds = timeDuration.seconds();
    return { days, hours, minutes, seconds };
  });

  const { data: serverTime } = useAsyncData(
    async () => {
      const { data } = await store.fetchServerTime();
      return data.items ? dayjs(data.items as number) : dayjs();
    },
    { server: false },
  );

  let intervalId: number | NodeJS.Timeout;
  const clear = () => {
    clearInterval(intervalId);
  };
  watch([serverTime, endTime], ([start]) => {
    if (start) {
      intervalId = setInterval(() => {
        const currentTime = dayjs();
        const elapsedTime = currentTime.diff(start); // 경과된 시간 계산
        remainingTime.value = dayjs(endTime).diff(start.add(elapsedTime, 'ms'));

        if (remainingTime.value <= 0) {
          remainingTime.value = 0;
          isFinish.value = true;
          clearInterval(intervalId);
        }
        isLoading.value = false;
      }, 1000);
    }
  });

  onUnmounted(() => {
    clearInterval(intervalId);
  });
  return { remaining, remainingToken, remainingTime, isFinish, isLoading, clear };
};
